<template>
  <v-container
    fluid
    class="pa-0 ma-0"
    style="scroll-snap-type: y mandatory;"
  >
    <v-row
      align="center"
      justify="center"
      no-gutters
    >
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="12"
      >
        <v-row
          align="center"
          justify="center"
          no-gutters
          class="pa-2 ma-2"
        >
          <v-col
            cols="12"
            md="12"
          >
            <v-sheet height="400" class="my-5">
              <v-calendar
                color="primary"
                type="day"
                locale="ko"
              >
                <template v-slot:interval="{ hour }">
                  <div
                    class="text-center"
                  >
                    {{ hour }} o'clock
                  </div>
                </template>
              </v-calendar>
            </v-sheet>

            <v-divider></v-divider>

            <v-alert
              border="top"
              colored-border
              elevation="2"
            >
              <p class="text-h6">process.env 변수</p>
              <v-divider></v-divider>
              <template v-for="(item, i) in processEnv">
                <p :key="i">{{ item }}</p>
              </template>
            </v-alert>

            <v-alert
              border="top"
              outlined
              type="info"
              dense
            >
              테스트시 참고할 것
            </v-alert>

            <v-alert
              outlined
              type="warning"
              prominent
              border="left"
            >
              회원가입 혹은 로그인을 성공하면, 유저의 정보를 auth 모듈안의 result 안에 담습니다.
            </v-alert>

            <v-alert
              text
              color="info"
            >
              <h3 class="text-h5 pb-2">회원가입 API 호출</h3>
              <div class="text-body-1">
                유저가 로그인을 하게 되면, 해당 정보를 로컬스토리지에 담고 페이지를 새로고침 할 때 해당 데이터를 가져와서 SET_LOGGED_INFO 를 호출하여 저장됐던 로그인 정보를 스토어에 넣어줍니다.
              </div>
              <v-divider
                class="my-4 info"
                style="opacity: 0.22"
              ></v-divider>
              <v-row
                align="center"
                no-gutters
              >
                <v-col class="grow">
                  <div class="text-body-1">모듈을 만들고 난 다음엔 모듈 인덱스에서 불러와서 combineReducers 안에 넣으세요.</div>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="shrink">
                  <v-btn
                    color="info"
                    outlined
                  >
                    Okay
                  </v-btn>
                </v-col>
              </v-row>
            </v-alert>

            <v-alert
              text
              outlined
              color="deep-orange"
              icon="mdi-fire"
            >
              <div class="text-body-2">만약에 파라미터가 객체형태로 들어오면 자동으로 JSON.stringify 를 해주고, 일반 문자열이라면 그대로 넣어줍니다.</div>
              <div class="text-body-2">조회를 할 때도 마찬가지로 객체형태라면 자동으로 파싱을 해줍니다.</div>
            </v-alert>
            <v-alert
              v-model="alert"
              dismissible
              color="cyan"
              border="left"
              elevation="2"
              colored-border
              icon="mdi-twitter"
            >
              You've got <strong>5</strong> new updates on your timeline!.
            </v-alert>

            <div class="text-center">
              <v-btn
                v-if="!alert"
                dark
                @click="alert = true"
              >
                Reset Alert
              </v-btn>
            </div>

          </v-col>
        </v-row>

        <v-divider class="my-3"></v-divider>

        <v-row
          class="px-2 mb-6"
          style="scroll-snap-align: center;"
        >
          <v-col cols="12" xs="12">
            <h3 class="text-h5">Grid System</h3>
          </v-col>

          <v-col
            v-for="n in 3"
            :key="n"
            cols="sm"
          >
            <v-card
              class="pa-2"
              outlined
              tile
            >
              be gutters
            </v-card>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          class="px-2 mb-6"
        >
          <v-col
            v-for="n in 3"
            :key="n"
            cols="sm"
          >
            <v-card
              class="pa-2"
              outlined
              tile
            >
              no-gutters
            </v-card>
          </v-col>
        </v-row>
        <v-row
          class="px-2 mb-6"
          justify="center"
          no-gutters
        >
          <v-col lg="2">
            <v-card
              class="pa-2"
              outlined
              tile
            >
              1 of 3
            </v-card>
          </v-col>
          <v-col md="auto">
            <v-card
              class="pa-2"
              outlined
              tile
            >
              Variable width content - 내용에 따라 넓이가 정해지는 col 영역임
            </v-card>
          </v-col>
          <v-col lg="2">
            <v-card
              class="pa-2"
              outlined
              tile
            >
              3 of 3
            </v-card>
          </v-col>
        </v-row>
        <v-row
          class="px-2 mb-6"
          no-gutters
        >
          <v-col md="4">
            <v-card class="pa-2" outlined tile>
              .col-md-4
            </v-card>
          </v-col>
          <v-col md="4" class="ml-auto">
            <v-card class="pa-2" outlined tile>
              .col-md-4 .ml-auto
            </v-card>
          </v-col>
        </v-row>

        <v-divider class="my-3"></v-divider>

        <v-bottom-navigation
          v-model="bottomNav"
          dark
          shift
        >
          <v-btn>
            <span>Video</span>
            <v-icon>ondemand_video</v-icon>
          </v-btn>
          <v-btn>
            <span>Music</span>
            <v-icon>music_note</v-icon>
          </v-btn>
          <v-btn
            @click="overlay = !overlay"
          >
            <span>오버레이</span>
            <v-icon>screen_share</v-icon>
          </v-btn>
          <v-btn>
            <span>Book</span>
            <v-icon>book</v-icon>
          </v-btn>
          <v-btn>
            <span>Image</span>
            <v-icon>image</v-icon>
          </v-btn>
        </v-bottom-navigation>
        <v-card
          class="mx-auto my-3"
          max-width="500"
        >
          <v-img
            src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
            height="200px"
          ></v-img>
          <v-card-title>
            <div>Top western road trips</div>
            <span class="grey--text text-subtitle-1">1,000 miles of wonder</span>
          </v-card-title>
          <v-card-actions>
            <v-btn text>공유</v-btn>
            <v-btn text color="purple">둘러보기</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="show = !show"
            >
              <v-icon>{{ show ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</v-icon>
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="show">
              <v-card-text>
                I'm a thing. But, like most politicians, he promised more than he could deliver. You won't have time for sleeping, soldier, not with all the bed making you'll be doing. Then we'll go with that data file! Hey, you add a one and two zeros to that or we walk! You're going to do his laundry? I've got to find a way to escape.
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>

        <v-divider class="my-3"></v-divider>

        <v-card
          :elevation="0"
          style="scroll-snap-align: center;"
        >

          <v-card-text>
            <p>[참석] [불참] [복대리] [변경] [진행] [종결] [상담] [보류] [유효] [해지]</p>
            <p>[완료] [준비] [연기] [취소] [청구] [감면] [미청구] [신청] [접수] [반려]</p>
            <p>[예정] [정산] [환불] [주문] [배송] [반품] [전달중] [실패] [성공]</p>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text
            class="pa-0 ma-0"
          >
            <v-list three-line>
              <v-list-item>
                <v-list-item-content class="pl-0">
                  <v-list-item-title class="text-body-2 ma-1">
                    2017.10.31
                    <v-chip x-small label class="my-0" color="blue" text-color="white">기일</v-chip>
                    <v-icon x-small color="amber darken-1">mdi-star</v-icon>
                    변론기일(가정별관 314호 14:00)
                    <!-- <span class="mx-2 font-weight-bold red--text">속행</span> -->
                    <span class="mx-2 font-weight-bold font-italic indigo--text">불참 복대리 변경</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    준비사항 > 기일 기한 준비 참석 불참 복대리 변경 진행 종결 상담 보류 등등
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-body-2 ma-1">
                    2018.09.23
                    <v-chip x-small label class="my-0" color="orange accent-4" text-color="white">송달</v-chip>
                    <v-icon x-small color="amber darken-1">mdi-star</v-icon>
                    피고1 소송대리인 법무법인 동신 담당변호사 김승모에게 송달
                    <span class="mx-2 font-weight-bold font-italic red--text">2016.01.12도달</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <div class="my-1">
                      <v-icon x-small>subdirectory_arrow_right</v-icon>
                      <span class="text--primary">변호사님 책상 위에 올려놓았습니다.</span> (홍길동 2017.10.31 09:11)
                    </div>
                    <div class="my-1">
                      <v-icon x-small>subdirectory_arrow_right</v-icon>
                      <span class="text--primary">알겠습니다.</span> (홍길동2 2017.10.31 12:11)
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-body-2 ma-1">
                    2017.10.31
                    <v-chip x-small label class="my-0" color="purple" text-color="white">업무</v-chip>
                    <v-icon x-small color="amber darken-1">mdi-star</v-icon>
                    약속 (장소 10:00)
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    홍길동 - 해당 팀원을 선택시 인사공유관리에 등록되어진 현재의 직위를 초기값
                    <span class="mx-2 font-italic">[진행,불참,쟁점,첨부,댓글,비공개]</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text>
            <v-btn color="primary" dark @click="fullDialog">full screen test</v-btn>
            <!-- <v-chip label
              :color="$icons['i01'].color"
              :text-color="$icons['i01'].textColor"
            >{{ $icons['i01'].caption }}</v-chip> -->
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text>
            <v-btn color="teal" dark @click="setIcons">아이콘 test</v-btn>
            <v-list two-line>
              <v-list-item
                v-for="(item, i) in dummyList" :key="i"
              >
                <v-list-item-content>
                  <v-list-item-title v-html="item.content"></v-list-item-title>
                  <v-list-item-subtitle>
                    <v-chip label x-small class="my-0"
                      :outlined="$icons[item.gubun].outlined"
                      :color="$icons[item.gubun].color"
                      :text-color="$icons[item.gubun].textColor"
                    >{{ $icons[item.gubun].caption }}</v-chip>
                    {{ item.date }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text>
            <v-chip x-small label color="blue" text-color="white">기일</v-chip>
            <v-chip x-small label color="red" text-color="white">기한</v-chip>
            <v-chip x-small label color="brown" text-color="white">제출</v-chip>
            <v-chip x-small label color="orange accent-4" text-color="white">송달</v-chip>
            <v-chip x-small label color="green" text-color="white">명령</v-chip>

            <v-chip x-small label color="black" text-color="white">접수</v-chip>
            <v-chip x-small label color="black" text-color="white">공고</v-chip>
            <v-chip x-small label color="black" text-color="white">종국</v-chip>
            <v-chip x-small label color="black" text-color="white">경유</v-chip>

            <v-chip x-small label color="grey darken-1" text-color="white">등록</v-chip>
            <v-chip x-small label color="grey darken-1" text-color="white">관리</v-chip>

            <v-chip x-small label color="teal lighten-1" text-color="white">계약</v-chip>
            <v-chip x-small label color="teal lighten-1" text-color="white">수금</v-chip>
            <v-chip x-small label color="teal lighten-1" text-color="white">비용</v-chip>
            <v-chip x-small label color="teal lighten-1" text-color="white">타임</v-chip>
            <v-chip x-small label color="teal lighten-1" text-color="white">거래</v-chip>
            <v-chip x-small label color="teal lighten-1" text-color="white">정산</v-chip>

            <v-chip x-small label color="purple" text-color="white">업무</v-chip>
            <v-chip x-small label color="purple" text-color="white">결재</v-chip>
            <v-chip x-small label color="purple" text-color="white">상담</v-chip>

            <!-- <v-chip x-small label color="indigo" text-color="white">문서</v-chip> 문제는 없어짐 -->

            <v-chip x-small label color="deep-orange darken-1" text-color="white">SMS</v-chip>
            <v-chip x-small label color="deep-orange darken-1" text-color="white">eMAIL</v-chip>

            <v-chip x-small label color="lime darken-3" text-color="white">의뢰인</v-chip>
            <v-chip x-small label color="lime darken-3" text-color="white">변호인</v-chip>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text>
            <v-chip x-small label outlined text-color="black">결재</v-chip>
            <v-chip x-small label outlined text-color="black">쟁점</v-chip>
            <v-chip x-small label outlined text-color="black">댓글</v-chip>
            <v-chip x-small label outlined text-color="black">이관</v-chip>

            <v-chip x-small label outlined color="grey lighten-1" text-color="grey darken-1">대기</v-chip>
            <v-chip x-small label outlined color="deep-orange accent-3" text-color="deep-orange accent-3">반려</v-chip>

            <v-chip x-small label outlined text-color="black">개별수임계약</v-chip>
            <v-chip x-small label outlined color="light-blue darken-4">고문계약</v-chip>

            <v-chip x-small label color="blue darken-4" text-color="white">진행</v-chip>
            <v-chip x-small label color="black" text-color="white">종결</v-chip>
            <v-chip x-small label color="red" text-color="white">상담</v-chip>
            <v-chip x-small label color="grey lighten-1" text-color="black">보류</v-chip>
          </v-card-text>

        </v-card>

        <v-divider></v-divider>

        <v-card-text>
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card
                class="mx-auto"
                max-width="344"
              >
                <v-img src="https://cdn.vuetifyjs.com/images/cards/forest-art.jpg"></v-img>

                <v-card-text>
                  <h2 class="text-h6 primary--text">Magento Forests</h2>
                  Travel to the best outdoor experience on planet Earth. A vacation you will never forget!
                </v-card-text>

                <v-card-title>
                  <v-rating
                    :value="4"
                    dense
                    color="orange"
                    background-color="orange"
                    hover
                    class="mr-2"
                  ></v-rating>
                  <span class="primary--text text-subtitle-2">64 Reviews</span>
                </v-card-title>

                <v-fade-transition>
                  <v-overlay
                    v-if="hover"
                    absolute
                    color="#036358"
                  >
                    <v-btn>See more info</v-btn>
                  </v-overlay>
                </v-fade-transition>
              </v-card>
            </template>
          </v-hover>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text>
          <h3>기본색상 테스트</h3>
          <v-chip
            pill
            v-for="(color, i) in $colors"
            :key="i"
            :color="color"
            :dark="color === 'black'"
            class="ma-1"
          >
            <v-avatar left>
              <v-icon>mdi-checkbox-marked-circle</v-icon>
            </v-avatar>
            선택
          </v-chip>
          <!-- <v-sheet
            v-for="(color, i) in $colors"
            :key="i"
            :color="color"
            tile
            height="30"
            width="80"
          ></v-sheet> -->
        </v-card-text>

        <v-divider></v-divider>

      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
    >
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>Settings</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card>
        <v-card-text>
          test
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

  </v-container>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      dummyList: [],
      bottomNav: 0,
      alert: true,
      show: false,
      overlay: false,
      processEnv: []
    }
  },

  watch: {
    overlay (val) {
      val && setTimeout(() => {
        this.overlay = false
      }, 2000)
    }
  },

  mounted () {
    // 중요: DOM이 로딩된 후에 다이얼로그를 띄워야 한다!
    // this.dialog = true
    // console.log(process.env)
    // console.log(`process.env.NODE_ENV = ${process.env.NODE_ENV}`)
    // this.processEnv = JSON.stringify(process.env)
    //
    for (const key in process.env) {
      // console.log(`${key}: ${process.env[key]}`)
      this.processEnv.push(`${key}: ${process.env[key]}\n`)
    }
  },

  methods: {
    fullDialog () {
      this.dialog = true
    },
    setIcons () {
      // 프로그램적으로 아이콘 적용
      // 리스트를 불러오면서 데이터에 따라 자동으로 아이콘이 세팅됨
      // 참고: @/lib/icons.js 파일을 main.js 에서 전역적으로 읽어서 선언했다
      this.dummyList = [
        { gubun: 'i01', content: '기일 테스트', date: '2019-10-21 13:23:23' },
        { gubun: 'i02', content: '기한 테스트', date: '2019-10-21 13:23:23' },
        { gubun: 'i03', content: '제출 테스트', date: '2019-10-21 13:23:23' },

        { gubun: 'i25', content: '결재 테스트', date: '2019-10-21 13:23:23' },
        { gubun: 'i26', content: '쟁점 테스트', date: '2019-10-21 13:23:23' },

        { gubun: 'i29', content: '대기 테스트', date: '2019-10-21 13:23:23' },
        { gubun: 'i30', content: '반려 테스트', date: '2019-10-21 13:23:23' },

        { gubun: 'i31', content: '개별수임계약 테스트', date: '2019-10-21 13:23:23' },

        { gubun: 'i33', content: '진행 테스트', date: '2019-10-21 13:23:23' },
        { gubun: 'i34', content: '종결 테스트', date: '2019-10-21 13:23:23' },
        { gubun: 'i35', content: '상담 테스트', date: '2019-10-21 13:23:23' },
        { gubun: 'i36', content: '보류 테스트', date: '2019-10-21 13:23:23' }
      ]
    }
  }
}
</script>

<style scoped>
/* .v-chip--small {
  height: 22px !important;
} */

/* .v-chip .v-chip__content {
  height: 22px;
  padding: 0 6px;
} */
</style>
